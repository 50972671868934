import { types as t } from 'mobx-state-tree';

const OrderModel = t.model({
  orderId: t.optional(t.number, 0),
  clientName: t.optional(t.maybeNull(t.string), ''),
  distributor: t.optional(t.maybeNull(t.string), ''),
  price: t.optional(t.maybeNull(t.number), 0),
  reservationDate: t.maybeNull(t.string),
  distributorLogo: t.optional(t.maybeNull(t.string), ''),
  createdAt: t.maybeNull(t.string),
  closingDate: t.maybeNull(t.string),
  passedTo: t.maybeNull(t.string),
  receivedFrom: t.optional(t.maybeNull(t.string), ''),
  completed: t.optional(t.maybeNull(t.boolean), false),
  article: t.optional(t.maybeNull(t.string), ''),
  productId: t.optional(t.maybeNull(t.string), ''),
  reserve: t.optional(t.maybeNull(t.number), 0),
});

export default OrderModel;
